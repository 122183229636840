<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" title="销售排行" width="1280px">
    <el-form class="head-container v s" label-position="right" label-width="78px">
      <el-form-item label="汇总级别:" class="filter-item">
        <el-radio-group v-model="query.shopAreaGroup" @change="redraw">
          <el-radio-button v-for="(v, k) in levels" :key="k" :label="k">{{v}}</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="排列方式:" class="filter-item">
        <el-radio-group v-model="query.saleValueGroup" @change="redraw">
          <el-radio-button v-for="(v, k) in fields" :key="k" :label="k">{{v.name}}</el-radio-button>
        </el-radio-group>
      </el-form-item>
    </el-form>

    <div class="bc-l">
      <div ref="bar" style="height: 300px;"></div>
    </div>

    <el-table v-loading="loading" row-key="id" :data="data" size="small" border>
      <el-table-column prop="rank" label="排名" width="80" align="center" />
      <el-table-column prop="name" :label="levels[query.shopAreaGroup]" width="160" show-overflow-tooltip />
      <el-table-column v-for="(v, k) in fields" :key="k" :prop="k" :label="v.name" width="160" :formatter="v.formatter" v-if="v.show" />
    </el-table>
    <el-pagination :current-page.sync="page" :page-sizes="[5,10,20, 50]" :page-size.sync="size" layout="total, sizes, prev, pager, next, jumper" :total="store.length" style="margin-top: 8px;" />
  </el-dialog>
</template>

<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      loading: false,
      dialog: false,
      store: [],
      page: 1,
      size: 10,
      levels: {
        shop: "门店",
        area: "区域",
      },
      fields: {
        saleNumber: {
          name: "销售数量",
          ratio: 1,
          unit: "",
          show: true,
        },
        realSumMoney: {
          name: "销售金额",
          ratio: 0.000001,
          formatter: this.$price,
          unit: "（万元）",
          show: true,
        },
        sendNumber: {
          name: "出货数量",
          ratio: 1,
          unit: "",
          show: true,
        },
        sendMoney: {
          name: "出货金额",
          ratio: 0.000001,
          formatter: this.$price,
          unit: "（万元）",
          show: true,
        },
        checkSaleNumber: {
          name: "已上报销售数量",
          ratio: 1,
          unit: "",
          show: true,
        },
        realCheckSumMoney: {
          name: "已上报销售金额",
          ratio: 0.000001,
          formatter: this.$price,
          unit: "（万元）",
          show: true,
        },
        checkSendNumber: {
          name: "已上报出货数量",
          ratio: 1,
          unit: "",
          show: true,
        },
        checkSendMoney: {
          name: "已上报出货金额",
          ratio: 0.000001,
          formatter: this.$price,
          unit: "（万元）",
          show: true,
        },
      },
      query: {
        saleValueGroup: "realSumMoney",
        shopAreaGroup: "area",
        page: 0,
        size: 10000,
      },
      chart: {
        bar: null,
      },
      params: {},
      target: null,
    };
  },
  computed: {
    data() {
      return (this.store || []).slice(
        (this.page - 1) * this.size,
        this.page * this.size
      );
    },
  },
  methods: {
    resetQuery(params, target) {
      this.params = params;
      this.target = target;
      this.dialog = true;
      this.redraw();
    },
    redraw() {
      if (!this.target) return false;
      let params = Object.assign({}, this.params, this.query);
      if (params.dateGroup === "year") {
        params.begDate = new Date(this.target.year, 0, 1).getTime();
        params.endDate = new Date(this.target.year + 1, 0, 1).getTime();
      } else if (params.dateGroup === "month") {
        params.begDate = new Date(
          this.target.year,
          this.target.month - 1,
          1
        ).getTime();
        params.endDate = new Date(
          this.target.year,
          this.target.month,
          1
        ).getTime();
      } else if (params.dateGroup === "day") {
        params.begDate = new Date(
          this.target.year,
          this.target.month - 1,
          this.target.day
        ).getTime();
        params.endDate = new Date(
          this.target.year,
          this.target.month - 1,
          this.target.day + 1
        ).getTime();
      }

      this.loading = true;
      request({
        url: "@host:analysis;api/analy/shop/sale/rank",
        method: "get",
        params,
      })
        .then((res) => {
          this.store = res.content;
          this.drawBarChart();
        })
        .finally((_) => {
          this.loading = false;
        });
    },

    drawBarChart() {
      if (!this.chart.bar) {
        this.chart.bar = echarts.init(this.$refs.bar, "light");
      }

      let xds = [],
        fn = this.query.saleValueGroup,
        fd = this.fields[fn],
        ds = this.store.map((o) => {
          xds.push(o.name);
          return Math.round(o[fn] * fd.ratio * 100) / 100;
        });

      this.chart.bar.clear();
      this.chart.bar.setOption({
        grid: {
          top: 20,
          right: 20,
          left: 20,
          bottom: 20,
        },
        title: {
          text: this.levels[this.query.shopAreaGroup] + fd.name + "前10",
          left: "center",
          top: 10,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: 40,
          right: 10,
          bottom: 10,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: xds.slice(0, 10),
        },
        yAxis: {
          type: "value",
          name: fd.name + fd.unit,
        },
        series: [
          {
            name: fd.name + fd.unit,
            type: "bar",
            data: ds.slice(0, 10),
          },
        ],
      });
    },
  },
};
</script>

