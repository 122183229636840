<template>
  <div class="app-container h">
    <div class="v no-flex" style="width: 320px; margin-right: 20px;">
      <div class="bold" style="line-height: 2;">直营门店</div>
      <div class="scroll-able-xy flex" style="border: 1px solid #DCDFE6; border-radius: 4px;">
        <dept-select v-model="query.treeNodeId" :type.sync="query.treeNodeType" @change="toQuery" />
      </div>
    </div>
    <div class="flex v">
      <el-form class="head-container e" label-position="top">
        <el-form-item label="分析维度" class="filter-item">
          <el-radio-group v-model="query.dateGroup">
            <el-radio-button label="year">年</el-radio-button>
            <el-radio-button label="month">月</el-radio-button>
            <el-radio-button label="day">日</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="时间范围" class="filter-item">
          <div class="h c" key="range-year" v-if="query.dateGroup === 'year'">
            <el-date-picker v-model="query.begDate" type="year" value-format="timestamp" placeholder="选择开始年" :clearable="false" style="width:150px" />
            <div class="padding-0-05">至</div>
            <el-date-picker v-model="query.endDate" type="year" value-format="timestamp" placeholder="选择结束年" :clearable="false" style="width:150px" />
          </div>
          <div class="h c" key="range-month" v-else-if="query.dateGroup === 'month'">
            <el-date-picker v-model="query.begDate" type="month" value-format="timestamp" placeholder="选择开始月" :clearable="false" style="width:150px" />
            <div class="padding-0-05">至</div>
            <el-date-picker v-model="query.endDate" type="month" value-format="timestamp" placeholder="选择结束月" :clearable="false" style="width:150px" />
          </div>
          <div class="h c" key="range-date" v-else-if="query.dateGroup === 'day'">
            <el-date-picker v-model="query.begDate" type="date" value-format="timestamp" placeholder="选择开始日" :clearable="false" style="width:150px" />
            <div class="padding-0-05">至</div>
            <el-date-picker v-model="query.endDate" type="date" value-format="timestamp" placeholder="选择结束日" :clearable="false" style="width:150px" />
          </div>
        </el-form-item>
        <el-form-item label="商品范围" class="filter-item">
          <quick-select url="api/brand" v-model="query.brandIds" filterable clearable placeholder="请选择品牌" style="width: 250px;" multiple />
          <tree-picker v-model="query.seriesId" :params="{brandIds:query.brandIds}" url="api/series/tree" clearable placeholder="请选择系列" style="width: 150px;" v-if="query.brandIds" />
          <tree-picker v-model="query.categoryId" url="api/category/tree" clearable placeholder="请选择分类" style="width: 150px;" />
        </el-form-item>

        <el-form-item label="指定商品" class="filter-item">
          <el-tooltip content="点击选择商品" placement="bottom">
            <div slot="content" v-if="query.goods && query.goods.length" style="max-width: 320px;">
              <template v-for="(g, i) in query.goods">{{g.name}}{{i < query.goods.length - 1 ? "、" : ""}}</template>
            </div>
            <sku-selector request-url="api/goods/sku" button-type :button-text="queryGoodsName" @submit="handleGoodsSelect">
              <i class="el-icon-circle-close padding-0-10" @click.stop="query.goods = null" v-if="query.goods && query.goods.length" />
            </sku-selector>
          </el-tooltip>
        </el-form-item>

        <el-form-item label="同比年度" class="filter-item" v-if="query.dateGroup !== 'year'">
          <el-input-number v-model="query.compareYear" :min="2000" :max="2099" :step="1" :precision="0" controls-position="right" placeholder="请输入年份" style="width: 120px;" />
        </el-form-item>
        <el-form-item label="只统计已上报" class="filter-item">
          <el-switch v-model="options.reportOnly" @change="draw" />
        </el-form-item>
        <el-form-item class="filter-item">
          <el-button type="success" icon="el-icon-search" :loading="loading" @click="toQuery" :disabled="!searchable">统计</el-button>
          <el-button type="success" @click="toDownload" :disabled="!searchable" icon="el-icon-download" :loading="downloadLoading">导出</el-button>
        </el-form-item>
      </el-form>

      <div class="flex scroll-able">
        <el-collapse :value="['sum', 'chart', 'data']">
          <el-collapse-item title="数据概览" name="sum">
            <div class="h s wrap" v-if="sumData">
              <div v-for="f in sumFields" :key="f.name" class="v c" style="width: 25%; margin-bottom: 20px;">
                <div class="bold" v-if="f.show">{{f.name}}</div>
                <div class="bold fc-p fs-large" v-if="f.show">{{f.price ? $price(sumData[f.field]) : sumData[f.field]}}</div>
                <div v-if="sumData.compareYearData && f.show">
                  <div class="h c">
                    <div class="fc-g ta-r" style="width: 80px;">{{sumData.compareYear}}年：</div>
                    <div>{{f.price ? $price(sumData.compareYearData[f.field]) : sumData.compareYearData[f.field]}}</div>
                  </div>
                  <div class="h c">
                    <div class="fc-g ta-r" style="width: 80px;">同比增长率：</div>
                    <div style="color: #cc4125">{{((sumData.compareYearData[f.ratioField] || 0) * 100).toFixed(2)}}%</div>
                  </div>
                  <div v-if="sumData.compMonthData" class="h c">
                    <div class="fc-g ta-r" style="width: 80px;">环比增长率：</div>
                    <div style="color: #6fa8dc">{{((sumData.compMonthData[f.ratioField] || 0) * 100).toFixed(2)}}%</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="fc-g ta-c" v-else>没有分析数据，请更改统计分析条件或稍候重试。</div>
          </el-collapse-item>
          <el-collapse-item title="分析图表" name="chart">
            <div v-if="store && store.length">
              <el-form class="head-container e" label-position="right" label-width="138px">
                <el-form-item label="显示同比：" class="filter-item">
                  <el-switch v-model="options.yearOnYearShow" @change="draw" />
                </el-form-item>
                <el-form-item label="显示数据：" class="filter-item">
                  <el-checkbox-group v-model="options.fields" size="mini" @change="draw">
                    <el-checkbox-button v-for="f in fieldDefines" :label="f.field" :key="f.field">{{f.name}}</el-checkbox-button>
                  </el-checkbox-group>
                </el-form-item>
              </el-form>
              <div ref="chart"></div>
            </div>
            <div class="fc-g ta-c" v-if="!store || !store.length">没有分析数据，请更改统计分析条件或稍候重试。</div>
          </el-collapse-item>
          <el-collapse-item title="数据表格" name="data">
            <template v-if="store && store.length">
              <el-table row-key="id" border :data="data" size="small" height="530">
                <el-table-column label="日期">
                  <template slot-scope="scope">
                    <span v-if="query.dateGroup ==='year'">{{scope.row.year}}</span>
                    <span v-if="query.dateGroup ==='month'">{{scope.row.year}}/{{scope.row.month}}</span>
                    <span v-if="query.dateGroup ==='day'">{{scope.row.year}}/{{scope.row.month}}/{{scope.row.day}}</span>
                  </template>
                </el-table-column>
                <!-- 未上报 -->
                <template v-if="isReported==false">
                  <el-table-column label="销售金额" align="center" key="1">
                    <el-table-column prop="realSumMoney" label="金额" :formatter="this.$price" min-width="120" />
                    <el-table-column prop="compareData.realSumMoney" label="同比金额" />
                    <el-table-column prop="realSumMoneyRatio" label="同比增长" min-width="100">
                      <template slot-scope="scope">
                        <span v-if="scope.row.realSumMoneyRatio">{{ Math.round(scope.row.realSumMoneyRatio * 10000) / 100}}%</span>
                        <span v-else>--</span>
                      </template>
                    </el-table-column>
                  </el-table-column>

                  <el-table-column label="销售数量" align="center" key="2">
                    <el-table-column prop="saleNumber" label="数量" />
                    <el-table-column prop="compareData.saleNumber" label="同比数量" />
                    <el-table-column prop="saleNumberRatio" label="同比增长" min-width="100">
                      <template slot-scope="scope">
                        <span v-if="scope.row.saleNumberRatio">{{ Math.round(scope.row.saleNumberRatio * 10000) / 100}}%</span>
                        <span v-else>--</span>
                      </template>
                    </el-table-column>
                  </el-table-column>

                  <el-table-column label="发货金额" align="center" key="3">
                    <el-table-column prop="sendMoney" label="金额" :formatter="this.$price" min-width="120" />
                    <el-table-column prop="compareData.sendMoney" label="同比金额" />
                    <el-table-column prop="sendMoneyRatio" label="同比增长" min-width="100">
                      <template slot-scope="scope">
                        <span v-if="scope.row.sendMoneyRatio">{{ Math.round(scope.row.sendMoneyRatio * 10000) / 100}}%</span>
                        <span v-else>--</span>
                      </template>
                    </el-table-column>
                  </el-table-column>
                  <el-table-column label="发货数量" align="center" key="4">
                    <el-table-column prop="sendNumber" label="数量" />
                    <el-table-column prop="compareData.sendNumber" label="同比数量" />
                    <el-table-column prop="sendNumberRatio" label="同比增长率" min-width="100">
                      <template slot-scope="scope">
                        <span v-if="scope.row.sendNumberRatio">{{ Math.round(scope.row.sendNumberRatio * 10000) / 100}}%</span>
                        <span v-else>--</span>
                      </template>
                    </el-table-column>
                  </el-table-column>
                </template>
                <!-- 已上报 -->
                <template v-if="isReported==true">
                  <el-table-column label="销售金额" align="center" key="5">
                    <el-table-column prop="realCheckSumMoney" label="金额" :formatter="this.$price" min-width="100" />
                    <el-table-column prop="compareData.realCheckSumMoney" label="同比金额" :formatter="this.$price" />
                    <el-table-column prop="realCheckSumMoneyRatio" label="同比增长">
                      <template slot-scope="scope">
                        <span v-if="scope.row.realCheckSumMoneyRatio">{{ Math.round(scope.row.realCheckSumMoneyRatio * 10000) / 100}}%</span>
                        <span v-else>--</span>
                      </template>
                    </el-table-column>
                  </el-table-column>
                  <el-table-column label="销售数量" align="center" key="6">
                    <el-table-column prop="checkSaleNumber" label="数量" />
                    <el-table-column prop="compareData.checkSaleNumber" label="同比数量" />
                    <el-table-column prop="checkSaleNumberRatio" label="同比增长">
                      <template slot-scope="scope">
                        <span v-if="scope.row.checkSaleNumberRatio">{{ Math.round(scope.row.checkSaleNumberRatio * 10000) / 100}}%</span>
                        <span v-else>--</span>
                      </template>
                    </el-table-column>
                  </el-table-column>
                  <el-table-column label="发货金额" align="center" key="7">
                    <el-table-column prop="checkSendMoney" label="金额" :formatter="this.$price" min-width="100" />
                    <el-table-column prop="compareData.checkSendMoney" label="同比金额" :formatter="this.$price" />
                    <el-table-column prop="checkSendMoneyRatio" label="同比增长" min-width="100">
                      <template slot-scope="scope">
                        <span v-if="scope.row.checkSendMoneyRatio">{{ Math.round(scope.row.checkSendMoneyRatio * 10000) / 100}}%</span>
                        <span v-else>--</span>
                      </template>
                    </el-table-column>
                  </el-table-column>
                  <el-table-column label="发货数量" align="center" key="8">
                    <el-table-column prop="checkSendNumber" label="数量" />
                    <el-table-column prop="compareData.checkSendNumber" label="同比数量" />
                    <el-table-column prop="checkSendNumberRatio" label="同比增长" min-width="100">
                      <template slot-scope="scope">
                        <span v-if="scope.row.checkSendNumberRatio">{{ Math.round(scope.row.checkSendNumberRatio * 10000) / 100}}%</span>
                        <span v-else>--</span>
                      </template>
                    </el-table-column>
                  </el-table-column>
                </template>
                <el-table-column width="150px">
                  <!-- <div slot="header" class="h r" slot-scope="scope">
                    <el-popover trigger="click">
                      <div class="v">
                        <el-checkbox v-for="(c, k) in columns" :key="k" v-model="c.show">{{c.label}}</el-checkbox>
                      </div>
                      <i class="fa fa-ellipsis-v" slot="reference"></i>
                    </el-popover>
                  </div>-->
                  <div class="row-commands" slot-scope="scope">
                    <el-button size="mini" type="text" @click="handleDrillStructure(scope.row)">商品结构</el-button>
                    <el-button size="mini" type="text" @click="handleSellRank(scope.row)">销售排行</el-button>
                  </div>
                </el-table-column>
              </el-table>

              <el-pagination :current-page.sync="page" :page-sizes="[5,10,20, 50]" :page-size.sync="size" layout="total, sizes, prev, pager, next, jumper" :total="store.length" style="margin-top: 8px;" />
            </template>
            <div class="fc-g ta-c" v-else>没有分析数据，请更改统计分析条件或稍候重试。</div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>

    <drill-structure ref="structure" />
    <drill-rank ref="rank" />
  </div>
</template>

<script>
import deptSelect from "./deptSelect";
import drillStructure from "./drill/structure";
import drillRank from "./drill/rank";
import skuSelector from "@/views/assembly/skuSelect";
import request from "@/utils/request";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";

export default {
  name: "trend",
  components: {
    deptSelect,
    drillStructure,
    drillRank,
    skuSelector,
  },
  data() {
    let now = new Date(this.$now.get());
    return {
      page: 1,
      size: 10,
      chart: null,
      isReported: false,
      // columns: {
      //   realSumMoney: { label: "销售金额", show: true },
      //   saleNumber: { label: "销售数量", show: true },
      //   sendMoney: {
      //     label: "发货金额",
      //     show: true,
      //     formatter: this.$price
      //   },
      //   sendNumber: { label: "发货数量", show: true }
      // },
      loading: false,
      goodsSpuName: null,
      store: [],
      sumData: null,
      sumFields: [
        {
          name: "订单总数量",
          field: "formCount",
          ratioField: "formCountRatio",
          show: true,
        },
        {
          name: "销售金额",
          price: true,
          field: "realSumMoney",
          ratioField: "realSumMoneyRatio",
          show: true,
        },
        {
          name: "单品数量",
          field: "saleNumber",
          ratioField: "saleNumberRatio",
          show: true,
        },
        {
          name: "出货数量",
          field: "sendNumber",
          ratioField: "sendNumberRatio",
          show: true,
        },
        {
          name: "出货金额",
          price: true,
          field: "sendMoney",
          ratioField: "sendMoneyRatio",
          show: true,
        },
        {
          name: "已上报销售金额",
          price: true,
          field: "realCheckSumMoney",
          ratioField: "realCheckSumMoneyRatio",
          show: false,
        },
        {
          name: "已上报销售数量",
          field: "checkSaleNumber",
          ratioField: "checkSaleNumberRatio",
          show: false,
        },
        {
          name: "已上报出货数量",
          field: "checkSendNumber",
          ratioField: "checkSendNumberRatio",
          show: false,
        },
        {
          name: "已上报出货金额",
          price: true,
          field: "checkSendMoney",
          ratioField: "checkSendMoneyRatio",
          show: false,
        },
      ],
      fieldDefines: [
        {
          field: "saleNumber_checkSaleNumber",
          name: "销售数量",
        },
        {
          field: "realSumMoney_realCheckSumMoney",
          name: "销售金额",
        },
        {
          field: "sendNumber_checkSendNumber",
          name: "出货数量",
        },
        {
          field: "sendMoney_checkSendMoney",
          name: "出货金额",
        },
      ],
      fields: {
        saleNumber_checkSaleNumber: {
          label: "销售数量",
          key: "saleNums",
          ratio: 1,
          yIndex: 1,
        },
        realSumMoney_realCheckSumMoney: {
          label: "销售金额",
          key: "saleMoney",
          ratio: 0.000001,
          yIndex: 0,
        },
        sendNumber_checkSendNumber: {
          label: "出货数量",
          key: "sendNums",
          ratio: 1,
          yIndex: 1,
        },
        sendMoney_checkSendMoney: {
          label: "出货金额",
          key: "sendMoney",
          ratio: 0.000001,
          yIndex: 0,
        },
      },
      query: {
        compareYear: now.getFullYear() - 1,
        treeNodeId: null,
        treeNodeType: null,
        dateGroup: "day",
        goods: null,
        begDate: new Date(
          now.getFullYear(),
          now.getMonth() - 1,
          now.getDate()
        ).getTime(),
        endDate: new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          23,
          59,
          59
        ).getTime(),
        skuId: null,
        brandIds: null,
        seriesId: null,
        categoryId: null,
        page: 0,
        size: 10000,
      },
      options: {
        compareYear: null,
        yearOnYearShow: false,
        reportOnly: false,
        fields: ["realSumMoney_realCheckSumMoney"],
        YOYFields: ["realSumMoney_realCheckSumMoney"],
      },
      params: null,
      downloadLoading: false,
    };
  },
  computed: {
    data() {
      return (this.store || []).slice(
        (this.page - 1) * this.size,
        this.page * this.size
      );
    },
    searchable() {
      return (
        this.query &&
        this.query.treeNodeId &&
        this.query.begDate &&
        this.query.endDate &&
        this.query.dateGroup
      );
    },
    queryGoodsName() {
      if (this.query.goods && this.query.goods.length) {
        let str = this.query.goods[0].name;
        if (this.query.goods.length > 1)
          str += "等" + this.query.goods.length + "个商品";
        return str;
      }
      return "选择商品";
    },
  },
  methods: {
    formatterRatio(row, column) {
      let s = row[column.property];
      if (!s) {
        return "--";
      } else {
        s = s * 100;
        return s.toFixed(2) + "%";
      }
    },
    handleGoodsSelect(res) {
      if (res) {
        this.query.goods = res;
      }
    },
    makeParams() {
      let params = JSON.parse(JSON.stringify(this.query));
      params.begDate = new Date(params.begDate);
      params.endDate = new Date(params.endDate);
      if (params.dateGroup === "year") {
        params.begDate = new Date(params.begDate.getFullYear(), 0, 1).getTime();
        params.endDate = new Date(
          params.endDate.getFullYear() + 1,
          0,
          1
        ).getTime();
        delete params.compareYear;
      } else if (params.dateGroup === "month") {
        params.begDate = new Date(
          params.begDate.getFullYear(),
          params.begDate.getMonth(),
          1
        ).getTime();
        params.endDate = new Date(
          params.endDate.getFullYear(),
          params.endDate.getMonth() + 1,
          1
        ).getTime();
      } else {
        params.begDate = new Date(
          params.begDate.getFullYear(),
          params.begDate.getMonth(),
          params.begDate.getDate()
        ).getTime();

        params.endDate = new Date(
          params.endDate.getFullYear(),
          params.endDate.getMonth(),
          params.endDate.getDate() + 1
        ).getTime();
      }
      if (params.goods && params.goods.length)
        params.skuIds = params.goods.map((o) => o.id);
      delete params.goods;
      return params;
    },
    toQuery() {
      if (this.options.reportOnly == false) {
        this.isReported = false;
      } else {
        this.isReported = true;
      }
      let params = this.makeParams();
      this.params = params;
      this.loading = true;
      request({
        url: "@host:analysis;api/analy/shop/sale/sum",
        method: "get",
        params,
      })
        .then((res) => {
          this.sumData = res;
        })
        .catch((err) => {
          this.sumData = {};
        })
        .finally((_) => {
          this.loading = false;
        });
      request({
        url: "@host:analysis;api/analy/shop/sale/trend",
        method: "get",
        params,
      })
        .then((res) => {
          this.store = res.content;
          this.$nextTick((_) => {
            this.draw();
          });
        })
        .catch((err) => {
          this.store = [];
        })
        .finally((_) => {
          this.loading = false;
        });
    },

    draw() {
      for (let i = 0; i < 4; i++) {
        this.sumFields[i].show = !this.options.reportOnly;
      }
      for (let i = 0; i < 4; i++) {
        this.sumFields[i + 4].show = this.options.reportOnly;
      }

      if (this.store && this.store.length) {
        let series = {};
        let times = []; //x轴日期
        let seriesData = [];

        this.store.forEach((d) => {
          if (d.year != 0 && d.month != 0 && d.day != 0) {
            let dt = d.year + "/" + d.month + "/" + d.day;
            times.push(dt);
          } else if (d.year != 0 && d.month != 0) {
            let dt = d.year + "/" + d.month;
            times.push(dt);
          } else {
            let dt = d.year;
            times.push(dt);
          }
        });

        this.options.fields.forEach((fn) => {
          let fns = fn.split("_");
          let fd = this.fields[fn];
          this.store.forEach((d) => {
            if (!series.hasOwnProperty(fd.key)) {
              series[fd.key] = {
                name: fd.label,
                type: "line",
                yAxisIndex: fd.yIndex,
                smooth: true,
                data: [],
              };
              seriesData.push(series[fd.key]);
            }
            series[fd.key].data.push(
              Math.round(
                (d[fns[this.options.reportOnly ? 1 : 0]] || 0) * fd.ratio * 100
              ) / 100
            );

            if (this.options.yearOnYearShow) {
              let yk = "yoy_" + fd.key;

              if (!series.hasOwnProperty(yk)) {
                series[yk] = {
                  name: "同比" + fd.label,
                  type: "line",
                  yAxisIndex: fd.yIndex,
                  smooth: true,
                  data: [],
                };
                seriesData.push(series[yk]);
              }
              series[yk].data.push(
                Math.round(
                  (d.compareData
                    ? d.compareData[fns[this.options.reportOnly ? 1 : 0]] || 0
                    : 0) *
                    fd.ratio *
                    100
                ) / 100
              );
            }
          });
        });

        if (!this.chart) {
          this.chart = echarts.init(this.$refs.chart, "light", {
            height: 320,
          });
        }
        this.chart.clear();
        this.chart.setOption({
          tooltip: {
            trigger: "axis",
          },
          legend: {
            show: true,
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          toolbox: {
            show: true,
            right: "4%",
            feature: {
              dataView: { show: true, readOnly: false },
              magicType: { show: true, type: ["line", "bar"] },
              restore: { show: true },
              saveAsImage: { show: true },
            },
          },

          xAxis: {
            type: "category",
            boundaryGap: false,
            data: times,
          },
          yAxis: [
            {
              type: "value",
              name: "金额（万元）",
              splitNumber: 5,
            },
            {
              type: "value",
              name: "数量",
              splitNumber: 5,
            },
          ],
          series: seriesData,
        });
      } else {
        this.chart = null;
      }
    },

    handleDrillStructure(row) {
      this.$refs.structure && this.$refs.structure.resetQuery(this.params, row);
    },

    handleSellRank(row) {
      this.$refs.rank && this.$refs.rank.resetQuery(this.params, row);
    },
    toDownload() {
      let params = this.makeParams();
      params.onlyReport = this.options.reportOnly;
      this.downloadLoading = true;
      download("@host:analysis;api/analy/shop/download/analySaleTrend", params)
        .then((result) => {
          downloadFile(result, "门店销售走势", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
};
</script>

